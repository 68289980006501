import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ObjectActions from './product-speed-analysis.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  ProductSpeedAnalysisDataInterface,
  ProductSpeedAnalysisDataTableColumnInterface,
  ProductSpeedAnalysisFilterInterface,
  ProductSpeedAnalysisInterface,
} from './product-speed-analysis.model';
import * as AppActions from '../../app/actions';
import { of } from 'rxjs';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { ProductSpeedAnalysisService } from '../../../shared/service/product-speed-analysis/product-speed-analysis.service';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import { HttpParams } from '@angular/common/http';
import * as moment from 'moment';
import { minutesToHm, mysqlTimestampFormat } from '../../../shared/helper/date';
import * as _ from 'lodash';
import { DecimalHelper } from '../../../shared/helper/decimal/decimal-helper';
import { AddProductSpeedResponseInterface } from '../../settings/product-speeds/product-speeds.model';
import { EntityTranslatorService } from '../../../shared/service/entity-translator/entity-translator.service';
import {HelperService} from "../../../shared/service/helper.service";

@Injectable()
export class ProductSpeedAnalysisEffects {
  constructor(
    private readonly actions$: Actions<ObjectActions.ProductSpeedAnalysisActions>,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly productSpeedAnalysisService: ProductSpeedAnalysisService,
    private readonly decimalHelper: DecimalHelper,
    private readonly entityTranslation: EntityTranslatorService,
    private readonly helperService: HelperService,
  ) {}

  getProductSpeedAnalysisData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.PRODUCT_SPEED_ANALYSIS_DATA_LOADING),
      switchMap((objectData: ObjectActions.ProductSpeedAnalysisDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        const filterData: ProductSpeedAnalysisFilterInterface = objectData.productSpeedAnalysisFilter;
        let params: HttpParams = new HttpParams().append('siteIds', encodeURI(filterData.site.join(',')));

        if (Array.isArray(filterData.line)) {
          params = params.append('lineIds', encodeURI(filterData.line.join(',')));
        }

        if (Array.isArray(filterData.product)) {
          params = params.append('productIds', encodeURI(filterData.product.join(',')));
        }

        if (filterData.dateRange.startDate && filterData.dateRange.endDate) {
          params = params
            .append('countType', filterData.countType[0])
            .append('durationTypes', JSON.stringify(filterData.durationTypes))
            .append(
              'startDate',
              this.helperService.convertFromGivenTimezoneToUTCAsISOFormat(filterData.dateRange.startDate),
            )
            .append(
              'endDate',
              this.helperService.convertFromGivenTimezoneToUTCAsISOFormat(filterData.dateRange.endDate),
            );
        }

        return this.productSpeedAnalysisService.productSpeedAnalysisDataLoading(params).pipe(
          map((response: GetManyResponseInterface<ProductSpeedAnalysisInterface>) => {
            response.data.forEach((productSpeedAnalysis: ProductSpeedAnalysisInterface) => {
              this.entityTranslation.translate(productSpeedAnalysis);
            });
            response.data.forEach((e: ProductSpeedAnalysisInterface) => {
              ProductSpeedAnalysisService.setProductSpeedAnalysisProductName(e);
            });
            return this.convertData(response.data);
          }),
          switchMap((payload: ProductSpeedAnalysisInterface[]) => {
            return of(new ObjectActions.ProductSpeedAnalysisDataLoaded(payload), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  setProductSpeed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.PRODUCT_SPEED_ANALYSIS_SET_PRODUCT_SPEED_LOAD),
      switchMap((objectData: ObjectActions.ProductSpeedAnalysisSetProductSpeedLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.productSpeedAnalysisService.setProductSpeed(objectData.productSpeed).pipe(
          switchMap((response: AddProductSpeedResponseInterface) => {
            return of(
              new ObjectActions.ProductSpeedAnalysisSetProductSpeedLoaded(response),
              new AppActions.HideLoader(),
            );
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  public convertData(productSpeedAnalysisData: ProductSpeedAnalysisInterface[]): ProductSpeedAnalysisInterface[] {
    productSpeedAnalysisData.forEach((data: ProductSpeedAnalysisDataTableColumnInterface) => {
      data.productUnit = _.camelCase(data.productUnit);
      data.catalogSpeed = ProductSpeedAnalysisEffects.getExpectedSpeed(data.originalData);
      data.observedSpeed = data.cleanMeanSummarySpeed.toString();
      data.conf = +this.decimalHelper.round(
        data.observedSpeed !== '0' && data.catalogSpeed !== '0' && data.originalDataCount > 1
          ? this.decimalHelper.multiply(
              this.computeConfLevel(data.cleanMeanSummarySpeed, data.cleanStdSummarySpeed, data.catalogSpeed),
              '100',
            )
          : '0',
      );
      data.originalData = data.originalData.map((originalDataRow: ProductSpeedAnalysisDataInterface) => {
        return {
          ...originalDataRow,
          quantityOrdered: originalDataRow.quantityOrdered ? originalDataRow.quantityOrdered.toString() : null,
          selectedCount: originalDataRow.selectedCount ? originalDataRow.selectedCount.toString() : null,
          summarySpeed: originalDataRow.summarySpeed ? originalDataRow.summarySpeed.toString() : null,
          productSpeed: originalDataRow.productSpeed ? originalDataRow.productSpeed.toString() : null,
          lineSpeed: originalDataRow.lineSpeed ? originalDataRow.lineSpeed.toString() : null,
          productLineSpeed: originalDataRow.productLineSpeed ? originalDataRow.productLineSpeed.toString() : null,
          formattedDuration: minutesToHm(originalDataRow.OEETotalDuration),
        };
      });

      this.calculateChartData('', data, Number(data.observedSpeed), data.catalogSpeed);
    });

    return productSpeedAnalysisData;
  }

  private static getExpectedSpeed(originalData: ProductSpeedAnalysisDataInterface[]): string {
    if (originalData.length > 0) {
      let expectedSpeed: string;
      const item = originalData[0];
      expectedSpeed = item.lineSpeed;

      if (item.productLineSpeed) {
        expectedSpeed = item.productLineSpeed.toString();
      } else if (item.productSpeed) {
        expectedSpeed = item.productSpeed.toString();
      }

      return expectedSpeed;
    }

    return null;
  }

  private computeConfLevel(cleanMeanSummarySpeed: number, cleanStdSummarySpeed: number, catalogSpeed: string): string {
    if (!cleanMeanSummarySpeed) {
      return '0';
    }

    let catalogDeviation: string = this.decimalHelper.divide(
      this.decimalHelper.abs(this.decimalHelper.subtract(catalogSpeed, cleanMeanSummarySpeed.toString())),
      cleanMeanSummarySpeed.toString(),
    );

    catalogDeviation = this.decimalHelper.min(catalogDeviation, '1') ? '1' : catalogDeviation;

    const normalizedStdSummarySpeed: number = Math.min(cleanStdSummarySpeed / cleanMeanSummarySpeed, 1);
    const penalty: string = this.decimalHelper.multiply(catalogDeviation, normalizedStdSummarySpeed.toString());
    const confidence: string = this.decimalHelper.subtract('1', penalty);

    return this.decimalHelper.isLessThan(confidence, '0.5') ? '0' : confidence;
  }

  public calculateChartData(
    group: string,
    groupItem: ProductSpeedAnalysisDataTableColumnInterface,
    bayesian: number,
    master: string,
  ): void {
    groupItem.xMin = 0;
    groupItem.xMax = 0;
    groupItem.xMean = bayesian;
    groupItem.xRef = master;
    groupItem.xBoxLow = Math.round(groupItem.cleanMeanSummarySpeed - 2 * groupItem.cleanStdSummarySpeed);
    groupItem.xBoxHigh = Math.round(groupItem.cleanMeanSummarySpeed + 2 * groupItem.cleanStdSummarySpeed);

    groupItem.originalData.forEach((item: ProductSpeedAnalysisDataInterface, index: number) => {
      if (index === 0) {
        groupItem.xMax = groupItem.xMin = Number(item.summarySpeed);
      }

      groupItem.xMin = Number(item.summarySpeed) < groupItem.xMin ? Number(item.summarySpeed) : groupItem.xMin;
      groupItem.xMax = Number(item.summarySpeed) > groupItem.xMax ? Number(item.summarySpeed) : groupItem.xMax;
    });
  }
}
